<template>
    <canvas class="chart"
            ref="chart">
    </canvas>
</template>

<script>
import objectHash from "object-hash";
import groupBy from "lodash/groupBy";

import {
    Chart,
    LineElement, BarElement, PointElement,
    BarController, LineController,
    CategoryScale, LinearScale, TimeScale, TimeSeriesScale, Decimation, Filler,
    Legend, Title, Tooltip,
} from "chart.js";

Chart.register(
    LineElement, BarElement, PointElement,
    BarController, LineController,
    CategoryScale, LinearScale, TimeScale, TimeSeriesScale, Decimation, Filler,
    Legend, Title, Tooltip,
);

const colorMap = new Map([
    ["chats", "#128c7e"],
    ["stickers", "#8c125d"],
    ["images", "#125d8c"],
    ["videos", "#8c4112"],
    ["audios", "#d0bb1b"],
]);

export default {
    name: "Chart",
    props: {
        labels: {
            type: Array,
        },
        chartData: {
            type: Array,
        },
    },
    data () {
        return {
            datasetHash: "",
            chartInstance: null,
        };
    },
    computed: {
        canvas () {
            return this.$refs.chart;
        },
        datasets () {
            const result = [];
            const data = groupBy(this.chartData ?? [], "result");
            if (data["moving-average"]) {
                result.push(
                    {
                        "type": "line",
                        "label": "3-day Moving Average",
                        "backgroundColor": "#343a40",
                        "data": data["moving-average"].map(({ _value }) => _value)
                    }
                );
            }
            return result.concat(
                Object
                    .keys(data)
                    .filter(key => key !== "moving-average")
                    .map(
                        key => ({
                            "type": "bar",
                            "label": data[key]?.[0]?.result,
                            "backgroundColor": colorMap.get(data[key]?.[0]?.result),
                            "data": data[key].map(({ _value }) => _value)
                        })
                    )
            );
        },
    },
    methods: {

    },
    async mounted () {
        const data = {
            "datasets": this.datasets,
            "labels": this.labels,
        };
        this.datasetHash = objectHash(data);
        this.chartInstance = new Chart(
            this.canvas,
            {
                data,
                options: {
                    "animation": false,
                    "maintainAspectRatio": false,
                    "legend": {
                        "display": false,
                    },
                    "scales": {
                        "x": {
                            "grid": {
                                "display": false,
                            },
                            "ticks": {
                                "display": false,
                            },
                            "stacked": true,
                        },
                        "y": {
                            "grid": {
                                "display": false,
                            },
                            "ticks": {
                                "display": false,
                            },
                            "stacked": true,
                        },
                    }
                }
            },
        );
    },
    watch: {
        datasets (newVal) {
            if (Array.isArray(newVal) && newVal.length > 0) {
                const { datasets, labels, } = this;
                const data = { datasets, labels, };
                if (this.datasetHash === objectHash(data)) {
                    return;
                }
                this.chartInstance.destroy();
                this.datasetHash = objectHash(data);
                this.chartInstance = new Chart(
                    this.canvas,
                    {
                        data,
                        "options": {
                            "maintainAspectRatio": false,
                            "legend": {
                                "display": false,
                            },
                            "scales": {
                                "x": {
                                    "grid": {
                                        "display": false,
                                    },
                                    "ticks": {
                                        "display": false,
                                    },
                                    "stacked": true,
                                },
                                "y": {
                                    "grid": {
                                        "display": false,
                                    },
                                    "ticks": {
                                        "display": false,
                                    },
                                    "stacked": true,
                                },
                            }
                        }
                    },
                );
            }
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
